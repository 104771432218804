.fullPageBackground {
  background-color: #000000; /* Change to your desired color */
  min-height: 100vh; /* Ensure it covers the full viewport height */
  width: 100%; /* Cover the full width */
}

.sphere {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 45vmin;
  height: 45vmin;
  animation: spinSphere 20s infinite linear;
  transform-style: preserve-3d;
}

.ring {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 45vmin;
  height: 45vmin;
  border-radius: 50%;
  overflow: hidden;
}

.ring1 {
  transform: rotateY(2deg) rotateX(2deg) rotateZ(3deg);
  box-shadow: 0 0 3px rgba(253, 169, 0, 0.1), inset 0 0 3px rgba(253, 169, 0, 0.1);
}

.ring2 {
  transform: rotateY(4deg) rotateX(4deg) rotateZ(6deg);
  box-shadow: 0 0 5px rgba(251, 169, 45, 0.1), inset 0 0 5px rgba(251, 169, 45, 0.1);
}

.ring3 {
  transform: rotateY(6deg) rotateX(6deg) rotateZ(9deg);
  box-shadow: 0 0 7px rgba(249, 168, 45, 0.1), inset 0 0 7px rgba(249, 168, 45, 0.1);
}

.ring4 {
  transform: rotateY(8deg) rotateX(8deg) rotateZ(12deg);
  box-shadow: 0 0 9px rgba(247, 168, 45, 0.1), inset 0 0 9px rgba(247, 168, 45, 0.1);
}

.ring5 {
  transform: rotateY(10deg) rotateX(10deg) rotateZ(15deg);
  box-shadow: 0 0 11px rgba(245, 167, 90, 0.1), inset 0 0 11px rgba(245, 167, 90, 0.1);
}

.ring6 {
  transform: rotateY(12deg) rotateX(12deg) rotateZ(18deg);
  box-shadow: 0 0 13px rgba(243, 167, 90, 0.1), inset 0 0 13px rgba(243, 167, 90, 0.1);
}

.ring7 {
  transform: rotateY(14deg) rotateX(14deg) rotateZ(21deg);
  box-shadow: 0 0 15px rgba(241, 166, 90, 0.1), inset 0 0 15px rgba(241, 166, 90, 0.1);
}

.ring8 {
  transform: rotateY(16deg) rotateX(16deg) rotateZ(24deg);
  box-shadow: 0 0 17px rgba(239, 166, 135, 0.1), inset 0 0 17px rgba(239, 166, 135, 0.1);
}

.ring9 {
  transform: rotateY(18deg) rotateX(18deg) rotateZ(27deg);
  box-shadow: 0 0 19px rgba(237, 165, 135, 0.1), inset 0 0 19px rgba(237, 165, 135, 0.1);
}

.ring10 {
  transform: rotateY(20deg) rotateX(20deg) rotateZ(30deg);
  box-shadow: 0 0 21px rgba(235, 165, 135, 0.1), inset 0 0 21px rgba(235, 165, 135, 0.1);
}

.ring11 {
  transform: rotateY(22deg) rotateX(22deg) rotateZ(33deg);
  box-shadow: 0 0 23px rgba(233, 164, 180, 0.1), inset 0 0 23px rgba(233, 164, 180, 0.1);
}

.ring12 {
  transform: rotateY(24deg) rotateX(24deg) rotateZ(36deg);
  box-shadow: 0 0 25px rgba(231, 164, 180, 0.1), inset 0 0 25px rgba(231, 164, 180, 0.1);
}

.ring13 {
  transform: rotateY(26deg) rotateX(26deg) rotateZ(39deg);
  box-shadow: 0 0 27px rgba(229, 163, 180, 0.1), inset 0 0 27px rgba(229, 163, 180, 0.1);
}

.ring14 {
  transform: rotateY(28deg) rotateX(28deg) rotateZ(42deg);
  box-shadow: 0 0 29px rgba(227, 163, 225, 0.1), inset 0 0 29px rgba(227, 163, 225, 0.1);
}

.ring15 {
  transform: rotateY(30deg) rotateX(30deg) rotateZ(45deg);
  box-shadow: 0 0 31px rgba(225, 162, 225, 0.1), inset 0 0 31px rgba(225, 162, 225, 0.1);
}

.ring16 {
  transform: rotateY(32deg) rotateX(32deg) rotateZ(48deg);
  box-shadow: 0 0 33px rgba(223, 162, 225, 0.1), inset 0 0 33px rgba(223, 162, 225, 0.1);
}

.ring17 {
  transform: rotateY(34deg) rotateX(34deg) rotateZ(51deg);
  box-shadow: 0 0 35px rgba(221, 161, 255, 0.1), inset 0 0 35px rgba(221, 161, 255, 0.1);
}

.ring18 {
  transform: rotateY(36deg) rotateX(36deg) rotateZ(54deg);
  box-shadow: 0 0 37px rgba(219, 161, 255, 0.1), inset 0 0 37px rgba(219, 161, 255, 0.1);
}

.ring19 {
  transform: rotateY(38deg) rotateX(38deg) rotateZ(57deg);
  box-shadow: 0 0 39px rgba(217, 160, 255, 0.1), inset 0 0 39px rgba(217, 160, 255, 0.1);
}

.ring20 {
  transform: rotateY(40deg) rotateX(40deg) rotateZ(60deg);
  box-shadow: 0 0 41px rgba(215, 160, 255, 0.1), inset 0 0 41px rgba(215, 160, 255, 0.1);
}

.ring21 {
  transform: rotateY(42deg) rotateX(42deg) rotateZ(63deg);
  box-shadow: 0 0 43px rgba(213, 159, 255, 0.1), inset 0 0 43px rgba(213, 159, 255, 0.1);
}

.ring22 {
  transform: rotateY(44deg) rotateX(44deg) rotateZ(66deg);
  box-shadow: 0 0 45px rgba(211, 159, 255, 0.1), inset 0 0 45px rgba(211, 159, 255, 0.1);
}

.ring23 {
  transform: rotateY(46deg) rotateX(46deg) rotateZ(69deg);
  box-shadow: 0 0 47px rgba(209, 158, 255, 0.1), inset 0 0 47px rgba(209, 158, 255, 0.1);
}

.ring24 {
  transform: rotateY(48deg) rotateX(48deg) rotateZ(72deg);
  box-shadow: 0 0 49px rgba(207, 158, 255, 0.1), inset 0 0 49px rgba(207, 158, 255, 0.1);
}

.ring25 {
  transform: rotateY(50deg) rotateX(50deg) rotateZ(75deg);
  box-shadow: 0 0 51px rgba(205, 157, 255, 0.1), inset 0 0 51px rgba(205, 157, 255, 0.1);
}

.ring26 {
  transform: rotateY(52deg) rotateX(52deg) rotateZ(78deg);
  box-shadow: 0 0 53px rgba(203, 157, 255, 0.1), inset 0 0 53px rgba(203, 157, 255, 0.1);
}

.ring27 {
  transform: rotateY(54deg) rotateX(54deg) rotateZ(81deg);
  box-shadow: 0 0 55px rgba(201, 156, 255, 0.1), inset 0 0 55px rgba(201, 156, 255, 0.1);
}

.ring28 {
  transform: rotateY(56deg) rotateX(56deg) rotateZ(84deg);
  box-shadow: 0 0 57px rgba(199, 156, 255, 0.1), inset 0 0 57px rgba(199, 156, 255, 0.1);
}

.ring29 {
  transform: rotateY(58deg) rotateX(58deg) rotateZ(87deg);
  box-shadow: 0 0 59px rgba(197, 155, 255, 0.1), inset 0 0 59px rgba(197, 155, 255, 0.1);
}

.ring30 {
  transform: rotateY(60deg) rotateX(60deg) rotateZ(90deg);
  box-shadow: 0 0 61px rgba(195, 155, 255, 0.1), inset 0 0 61px rgba(195, 155, 255, 0.1);
}

.ring31 {
  transform: rotateY(62deg) rotateX(62deg) rotateZ(93deg);
  box-shadow: 0 0 63px rgba(193, 154, 255, 0.1), inset 0 0 63px rgba(193, 154, 255, 0.1);
}

.ring32 {
  transform: rotateY(64deg) rotateX(64deg) rotateZ(96deg);
  box-shadow: 0 0 65px rgba(191, 154, 255, 0.1), inset 0 0 65px rgba(191, 154, 255, 0.1);
}

.ring33 {
  transform: rotateY(66deg) rotateX(66deg) rotateZ(99deg);
  box-shadow: 0 0 67px rgba(189, 153, 255, 0.1), inset 0 0 67px rgba(189, 153, 255, 0.1);
}

.ring34 {
  transform: rotateY(68deg) rotateX(68deg) rotateZ(102deg);
  box-shadow: 0 0 69px rgba(187, 153, 255, 0.1), inset 0 0 69px rgba(187, 153, 255, 0.1);
}

.ring35 {
  transform: rotateY(70deg) rotateX(70deg) rotateZ(105deg);
  box-shadow: 0 0 71px rgba(185, 152, 255, 0.1), inset 0 0 71px rgba(185, 152, 255, 0.1);
}

.ring36 {
  transform: rotateY(72deg) rotateX(72deg) rotateZ(108deg);
  box-shadow: 0 0 73px rgba(183, 152, 255, 0.1), inset 0 0 73px rgba(183, 152, 255, 0.1);
}

@keyframes spinSphere {
  from {
    transform: rotateZ(0deg) rotateX(0deg) rotateY(0deg);
  }
  to {
    transform: rotateZ(360deg) rotateX(-360deg) rotateZ(360deg);
  }
}